// colors

$color-primary: #3c5132;

//***********
// Navbar
//***********
/* Medium devices (landscape tablets, 768px and up) */
.navigation-top{
    background:
    linear-gradient(to right, #3c5132 0%, rgb(4, 189, 44) 51%, #3c5132  100%);

    .link_top {
        color: white;
        text-decoration: none;
        margin: 0px 0px 3px 20px; 
        text-transform: uppercase;
        font-size: 15px;
        
        &:hover {
            color: yellow;
        }
    }
}

@media only screen and (min-width: 768px) {

    .navbar {
        background-color: #e1f5e3;
        z-index: 900;
        -webkit-box-shadow: 0px 2px 5px #e1f5e3;
        -moz-box-shadow: 0px 2px 5px #e1f5e3;
        box-shadow: 0px 2px 5px #c0d4c2;
        transition: top 1s;
    
        .navbar_link {
            margin: 6px 15px;
            text-decoration: none;
            color: grey;
            font-size: 20px;
            display: inline-block;
            padding-bottom:2px;
            background-image:  linear-gradient(to right, #3c5132 0%, rgb(4, 189, 44) 51%, #3c5132  100%);
            background-position: 0 100%; /*OR bottom left*/
            background-size: 0% 3px;
            background-repeat: no-repeat;
            transition:
              background-size 0.3s,
              background-position 0s 0.3s; /*change after the size immediately*/
         
          
            &:hover {
                color: black;
                background-position: 100% 100%; /*OR bottom right*/
                background-size: 100% 3px;
            }
          

            &-icon {
                text-decoration: none;
                color: #3c5132;
                display: block;

                .navbar_icon {
                    padding: 10px;
                    font-size: 30px;
                    height: 30px;
                }

            }
        }

        .current_link{
            color: black;
            background-position: 100% 100%; /*OR bottom right*/
            background-size: 100% 3px;
        }
    }

    .navbar_box_mini {
        display: none;
    }
}

@media only screen and (max-width: 768px) {

    .navbar_box {
        display: none;
    }

    .navbar {

        background-color: #e1f5e3;
        z-index: 900;
        -webkit-box-shadow: 0px 2px 5px #e1f5e3;
        -moz-box-shadow: 0px 2px 5px #e1f5e3;
        box-shadow: 0px 2px 5px #c0d4c2;
        transition: top 1s;

        .menu_icon {
            float: right;
            color: grey;
            padding: 20px 10px 10px;
            cursor: pointer;
        }

        .close_icon {
            float: right;
            padding: 20px 10px 10px;
            color: grey;
            cursor: pointer;
        }


        .title {
            padding: 20px 0;
            font-size: 20px;
            color: grey;
        }
        
        .navbar_link_container {
            display: inline-grid;
        }

        .navbar_link {
            // padding: 0 20px;
            // text-decoration: none;
            // color: #7fc348;
            // font-size: 25px;
            // line-height: 40px;

            margin: 6px 15px;
            text-decoration: none;
            color: grey;
            font-size: 20px;
            display: inline-block;
            padding-bottom:2px;
            background-image:  linear-gradient(to right, #3c5132 0%, rgb(4, 189, 44) 51%, #3c5132  100%);
            background-position: 0 100%; /*OR bottom left*/
            background-size: 0% 3px;
            background-repeat: no-repeat;
            transition:
              background-size 0.3s,
              background-position 0s 0.3s; /*change after the size immediately*/
    
            &:hover {
                color: black;
            }
    
            &-icon {
                text-decoration: none;
                color: #3c5132;
                // display: block;
    
                .navbar_icon {
                    padding: 10px;
                    font-size: 50px;
                    height: 40px;
                }
    
            }
        }

        .display {
            display: inline-grid ;
        }
        
        .hide {
            display: none;
        }

    }

    .navbar_box_mini {
        display: block;
        width: 100%;
    }
    
}

  
.carousel_container {
    // margin-top: 5px;

    .carousel_item_container {
        height: 500px;

        @media only screen and (max-width: 768px) {
            height: 200px;
        }

        .image {
            width: 100%;
            height: 500px;

            @media only screen and (max-width: 768px) {
                height: 200px;
            }
    
        }

        .carousel_title_container {
            position: absolute;
            left: 6%;
            top: 50px;
            text-align: left;
            width: 60%;

            @media only screen and (min-width: 1600px) {
                left: 15%;
            }
    
            .carousel_title {
                margin: 0 100px;
    
                @media only screen and (max-width: 768px) {
                    display: none;
                }
            }
    
            @media only screen and (max-width: 768px) {
                position: relative;
                top: 0;
                text-align: center;
                background-color: transparent;
                width: 100%;
                font-size: 10px;
                color: #cbeeb1;
            }
    
        }

    }
}
  
.sticky {
    position: -webkit-sticky;
    position: sticky;
    top: -1px;
    width: 100%;
    transition: top 1s;
}

//***********
// Footer
//***********

.footer {
    background-color: #e1f5e3;
    margin-top: 100px;

    .footer_links_container {
       
        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;
            
            li {
                padding: 2px 0;

                a {
                    font-size: 20px;
                }
            } 
        }
    }

    .footer_link {
        padding: 10px;
        text-decoration: none;
        color: grey;
        font-size: 16px;

        &:hover {
            color: black;
        }
    }

    .footer_link_bottom {
        color: white;
        text-decoration: none;
        margin: 0px 0px 5px 20px; 
        font-size: 15px;
        
        &:hover {
            color: yellow;
        }
    }

    

    

    .footer_box {
        background-color: #3c5132;
        height: 30px;
        padding: 5px;
    }

    .footer_small_box {
        background:
        linear-gradient(to right, #3c5132 0%, rgb(4, 189, 44) 51%, #3c5132  100%);
        height: 100%;
        padding: 5px;
        width: 100%;
        margin-top: 20px;
        justify-content: center;
    }

    .author {
        color: #cbeeb1;
        font-size: 12px;
        // position: absolute;
        right: 10px;
        padding: 5px
    }
    .img_container {
        padding: 20px;
        border-radius: 30px;
        
        img {
            width: 100%;
            height: 240px;
            border-radius: 30px;

            @media only screen and (max-width: 768px) {
                height: 160px;
            }
        }
    }

    .social-media_container {

        text-align: center;
        color: black;
        margin-bottom: 15px;

        ul {
            display: flex;
            flex-direction: row;
            justify-content: center;
            
            li {
                background: #3c5132;
                border-radius: 10px;
                margin-right: 20px;
                padding: 10px;

                &:hover {
                    color: yellow;
                    background: radial-gradient( rgb(4, 189, 44)  20%, #3c5132 90%);
                    -webkit-box-shadow: 0px 1px 5px 1px rgb(4, 189, 44);
                    -moz-box-shadow: 0px 1px 5px 1px rgb(4, 189, 44);
                    box-shadow: 0px 1px 2px 1px rgb(4, 189, 44);

                    a {
                        // opacity: 0.7;
                        color:white;
                    }
                }

                a {
                    color: white;
                    text-decoration: none;

                    &:hover {
                        color: yellow;
                    }
                }
                
            }

        }
    }
   
}
  
//***********
// Language Selector
//***********
  
.language-selector {
    position: relative;
    display: inline-block; 
    padding: 2px 0  2px 20px ;
    

    &:hover {

        .language-selector-content {
            display: block;
            z-index: 999;
        }

        .language-selector_btn {
            background-color: #3e8e41;
        }

    }

    .language-selector_btn {
        color: white;
        padding: 0;
        font-size: 16px;
        border: none;
        cursor: pointer;
        z-index: 999;
        line-height: 8px;
    }

    &-content {
        display: none;
        position: absolute;
        right: 0;
        background-color: #cbeeb1;
        min-width: 120px;
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
        z-index: 2;
        

        @media only screen and (max-width: 768px) {
            left: 20px;
        }

        div {
            color: black;
            padding: 10px;
            display: block;
            cursor: pointer;
        

        &:hover {
            background-color: #7fc348;
            cursor: pointer;
        }

        .text {
            padding: 5px;
        }
        }
    }

    .language_icon {
        width: 25px;
    }
}

//***********
// Banner
//***********

.banner-container {
    margin-top: 5px;
    position: relative;
    background-color: #1e261e !important;

    img {
        width: 100%;
    }

    .banner_title_container {
        position: absolute;
        top: 10px;
        text-align: center;
        background-color: white;
        opacity: 0.7;
        width: 100%;

        .banner_title {
            margin: 0 100px;

            @media only screen and (max-width: 768px) {
                display: none;
            }
        }

        @media only screen and (max-width: 768px) {
            position: relative;
            top: 0;
            text-align: center;
            background-color: transparent;
            width: 100%;
            font-size: 10px;
            color: #cbeeb1;
        }

    }
    

    .icon_arrow-down_container {
        position: absolute;
        bottom: 50px;
        left: 48%;
        animation-name: example;
        animation-duration: 1s;
        animation-iteration-count: infinite;

        @media only screen and (max-width: 768px) {
            display: none;
        }

        @keyframes example {
            0%   {bottom: 50px;}
            50%  {bottom: 45px;}
            100% {bottom: 50px;}
          }

        .icon_arrow-down {
            font-size: 50px;
            color: #3c5132;
            background-color: white;
            border-radius: 50px;
        }

        a:hover {

            .icon_arrow-down {
                font-size: 50px;
                color: #7fc348;
            }
        }
    }

    .banner_btn_container {
        position: absolute;
        bottom: 150px;
        display: flex;
        width: 100%;
        justify-content: center;

        @media only screen and (max-width: 768px) {
            display: none;
        }
    
        .banner_btn_contact {
            background-color: #3c5132;
            border: 2px solid #7fc348;
            border-radius: 20px;
            color: #7fc348;
            font-size: 20px;
            padding: 20px;
            text-decoration: none;
            
    
            &:hover {
                color: #cbeeb1;
                background-color: #3c5132;
                -webkit-box-shadow: 0px 2px 5px 2px #7fc348;
                -moz-box-shadow: 0px 2px 5px 2px #7fc348;
                box-shadow: 0px 2px 5px 2px #7fc348;
    
            }
        }
    }

}

.section_container {
    margin-top: 50px;


    @media only screen and (max-width: 768px) {
        margin-top: 0;
    }

    .title {
        text-align: center;
        font-size: 27px;
        font-weight: bold;
        margin: 40px 0;
        padding: 20px 0;
    
        @media only screen and (max-width: 768px) {
            padding: 20px 0;
        }
    }

    .header {
        text-align: center;
        font-size: 35px;
        font-weight: bold;
        margin: 20px 0;
        padding: 20px 0;
        font-weight: bold;
        color: white;
    
        @media only screen and (max-width: 768px) {
            padding: 20px 0;
        }
    }

}


.page_title {
    text-align: center;
    // padding-top: 100px;

    @media only screen and (max-width: 768px) {
        padding: 20px 0;
    }
}



//***********
// Main Container
//***********

.main_container {
    
    .img_container {
        padding: 10px;

        img {
            width: 100%;
            display: block;
            border-radius: 30px;
        }
    }

    .list_title {
        padding: 0 10px;
    }

    .list_container {
        padding: 10px;
        font-size: 20px;
        color: #3c5132;

        .list_icon {
            font-size: 18px;
            color:#3c5132;
            margin-right: 10px;
        }

        @media only screen and (max-width: 768px) {
            font-size: inherit;
        }
    }

    .text_container {
        padding: 20px 10px;
        font-size: 20px;
        text-align:justify;

        @media only screen and (max-width: 768px) {
            font-size: 15px;
            font-weight: 100;
        }
    }
}

.btn_container {
    text-align: center;
    margin: 50px;

    .btn_contact {

        border: 2px solid #3c5132;
        border-radius: 20px;
        color: #cbeeb1;
        font-size: 20px;
        padding: 10px 20px;
        text-decoration: none;
        background-color: #3c5132;
        text-transform: uppercase;

        &:hover {
            color: #cbeeb1;
            background-color: #3c5132;
            -webkit-box-shadow: 0px 2px 5px 2px #3c5132;
            -moz-box-shadow: 0px 2px 5px 2px #3c5132;
            box-shadow: 0px 2px 5px 2px #3c5132;

        }
    }
}

.contact_container {

    .contact_title {
        text-align: center;
        margin: 30px 0;
        font-size: 40px;
        font-weight: bold;
    }

    .contact_box {

        border: 1px solid #3c5132;
        border-radius: 10px;
        padding: 10px 50px;
        min-height: 220px;
        min-width: 250px;
        width: 100%;
        margin-bottom: 30px;
        -webkit-box-shadow: 0px 2px 5px 2px #3c5132;
        -moz-box-shadow: 0px 2px 5px 2px #3c5132;
        box-shadow: 0px 2px 5px 2px #3c5132;

        a {
            color: #3c5132;
            text-decoration: none;

            &:hover {
                color: #7fc348;
            }
        }
    }

    .contact_icon {
        margin-right: 20px;
        font-size: 20px;
    }
}

.side_box {
    position: fixed;
    right: 0;
    bottom: 30%;
    z-index: 999;

    ul {
        display: flex;
        flex-direction:column;
        justify-content: start;
        
        li {
            background: #3c5132;
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
            padding: 5px;

            &:hover {
                color: yellow;
                background: radial-gradient( rgb(4, 189, 44)  20%, #3c5132 90%);
                -webkit-box-shadow: 0px 1px 5px 1px rgb(4, 189, 44);
                -moz-box-shadow: 0px 1px 5px 1px rgb(4, 189, 44);
                box-shadow: 0px 1px 2px 1px rgb(4, 189, 44);

                a {
                    color:white;
                }
            }

            a {
                color: white;
                text-decoration: none;

                &:hover {
                    color:yellow;
                }
            }
            
        }
    }
}

.card_link {
    padding: 10px;
    text-decoration: none;
    color: #3c5132;
    font-size: 15px;
    border: 1px solid #3c5132;
    border-radius: 10px;

    &:hover {
        color: #cbeeb1;
        background-color: #3c5132;
        -webkit-box-shadow: 0px 2px 5px 2px #7fc348;
        -moz-box-shadow: 0px 2px 5px 2px #7fc348;
        box-shadow: 0px 2px 5px 2px #7fc348;
    }
}


.card {
    position: relative;
    height: 370px;
    border-radius: 30px !important;
    text-align: center;

    .card_black_cover {
        background-color: #000;
        opacity: 0.5;
        position: absolute;
        bottom: 0;
        height: 20%;
        width: 100%;
        border-radius: 30px;
        z-index: 1;
    }

    .card_content {
        position: absolute;
        bottom: 0;
        color: #fff;
        border-radius: 30px;
        text-align: center;
        width: 100%;
        z-index: 2;
    
        .card_title {
            font-size: 30px;
            text-align: center;
            margin-left: -16px;
            margin-right: 16px;
            
        }
    }

    &:hover {
        -webkit-box-shadow: 0px 2px 5px 2px #7fc348;
        -moz-box-shadow: 0px 2px 5px 2px #7fc348;
        box-shadow: 0px 2px 5px 2px #7fc348;
        transition: transform 2s;

        .card_title {
            -webkit-transition: all 500ms ease;
            -moz-transition: all 500ms ease;
            -ms-transition: all 500ms ease;
            -o-transition: all 500ms ease;
            transition: all 500ms ease;
        }

        img {
            transition: transform .5s;
            transform: scale(1.1);
        }
    }

    
}


.title_box {
    text-decoration: none;

    .card_title_box {
        text-align: center;
        border: 1px solid #3c5132;
        border-radius: 30px;
        padding: 50px;
        font-size: 40px;
        text-decoration: none;
        color: #3c5132;
    }

    &:hover {

        .card_title_box {
            -webkit-box-shadow: 0px 2px 5px 2px #3c5132;
            -moz-box-shadow: 0px 2px 5px 2px #3c5132;
            box-shadow: 0px 2px 5px 2px #3c5132;
            background-color: #3c5132;
            color: #7fc348;
        }
       
    }

}

.achievement-container {
    color: white !important; 
    margin-top: 100px;
    position: relative;
    padding: 10px 0 50px 0;
   

    .black_box {
        position: absolute;
        top: 0;
        background-color: #000;
        opacity: 0.5;
        height: 100%;
        width: 100%;
    }

    .content_box {
        top: 20px;
        width: 100%;

        @media only screen and (max-width: 768px) {
            position: relative;
        }
    }

    .achievement_title {
        text-align: center;
        font-size: 40px;
        font-weight: bold;
        position: relative;
        z-index: 2;

        @media only screen and (max-width: 768px) {
            margin: 30px 0 30px;
            font-size: 30px;
            font-weight: bold;
        }
    }

    .achievement_card {
        background: transparent;
        min-height: 300px;
        color: white;
        position: relative;
        width: 100%;
        -webkit-box-shadow: 0px 1px 2px 1px #7fc348;
        -moz-box-shadow: 0px 1px 2px 1px #7fc348;
        box-shadow: 0px 1px 2px 1px #7fc348;
        border-radius: 30px;

        @media only screen and (max-width: 768px) {
            min-height: 100px;
        }

        .card_black_cover{
            // background-color: #000;
            // opacity: 0.3;
            position: absolute;
            top: 0;
            height: 100%;
            width: 100%;
        }

        .card_icon_container {
            color:white;
            text-align: center;
            margin: 20px;

            .card_icon {
                font-size: 60px;

                @media only screen and (max-width: 768px) {
                    font-size: 40px;
                }
            }
        }

        .card_title_container {
            color:white;
            text-align: center;
        }

        .card_subtitle_container {
            color:white;
            text-align: center;
            font-size: 70px;

            @media only screen and (max-width: 768px) {
                font-size: 40px;
                margin-bottom: 20px;
            }
        }
    }
}

.upload-box {
    border-radius: 5px;
    border-color: #cbeeb1;
  }

.btn-upload {
    border: 1px solid #3c5132;
    border-radius: 10px;
    color: #cbeeb1;
    font-size: 16px;
    padding: 10px;
    text-decoration: none;
    background: #3c5132;

    &:hover {
        color: #cbeeb1;
        background-color: #3c5132;
        -webkit-box-shadow: 0px 1px 2px 1px #3c5132;
        -moz-box-shadow: 0px 1px 2px 1px #3c5132;
        box-shadow: 0px 1px 2px 1px #3c5132;
        cursor: pointer;

    }
}


.bg-image-full-1 {
    background: url('../assets/banner5.jpg') no-repeat center center; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.bg-image-full-2 {
    background: url('../assets/images/bg2.jpg') no-repeat center center; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.box1 {
    color: black;
    background-color: #ffffff;
    opacity: 0.7;
    -webkit-box-shadow: 0px 2px 5px 2px #7fc348;
    -moz-box-shadow: 0px 2px 5px 2px #7fc348;
    box-shadow: 0px 2px 5px 2px #7fc348;
    border-radius: 30px;
    padding: 20px;
    margin: 50px;
    text-align: center;

    @media only screen and (max-width: 768px) {
        margin: 10px;
    }
}


.accordion-box {
    border-radius: 30px;
    margin: 10px 0;
    -webkit-box-shadow: 0px 2px 5px 2px #7fc348;
    -moz-box-shadow: 0px 2px 5px 2px #7fc348;
    box-shadow: 0px 2px 5px 2px #7fc348;
}

.youtube-box {
    height: 600px;
    margin: 30px 0;

    @media only screen and (max-width: 768px) {
        height: 300px;
    }
}

.accordion-subtitle {
    @media only screen and (max-width: 768px) {
        margin-top: 30px !important;
    }
}


         
.button_primary {
    background-image: linear-gradient(to right, #3c5132 0%, rgb(4, 189, 44) 51%, #3c5132  100%) !important ;
    transition: 0.5s !important;
    background-size: 200% auto !important;
    color: white !important;            
    border-radius: 20px !important;
    padding: 10px 20px !important;
    text-decoration: none !important;
    text-transform: uppercase !important;

  &:hover {
    color: yellow !important;
    background-position: right center !important; 
    -webkit-box-shadow: 0px 1px 5px 1px rgb(4, 189, 44);
    -moz-box-shadow: 0px 1px 5px 1px rgb(4, 189, 44);
    box-shadow: 0px 1px 2px 1px rgb(4, 189, 44);
  }

} 

.button_secondary {
    background-color: #3c5132 !important;
    border-radius: 20px !important;
    padding: 10px 20px !important;
    color: white !important;
    text-decoration: none !important;
    
    &:hover {
        background: radial-gradient( rgb(4, 189, 44)  20%, #3c5132 90%);
        -webkit-box-shadow: 0px 1px 5px 1px rgb(4, 189, 44);
        -moz-box-shadow: 0px 1px 5px 1px rgb(4, 189, 44);
        box-shadow: 0px 1px 2px 1px rgb(4, 189, 44);
       
    }
}

.btn_registration {
    margin: 0 0 20px 10px;
}

.card_media {
    height: 300px;

    @media only screen and (max-width: 768px) {
        height: auto;
    }
}

.image_list {
    min-height: 500px;
    width: 100%;
    // max-height: 1200px;
    // height: 700px;
}