@import "./styles/components";
@import "./styles/fonts";

body {
//  background-color:#e1f5e3;
//  color: #cbeeb1;
  font-family: 'Roboto', sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    height: 90px;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.center {
  text-align: center;
}

/* for change border color*/
.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline{
  border-color: #3c5132 !important;
}


.MuiTextField-root > label {
  color: #3c5132;
}

.MuiTextField-root > .MuiFormLabel-root.Mui-focused {
  color: #3c5132;
}

.MuiFormLabel-root.Mui-focused {
  color: #3c5132 !important;
}

.MuiSvgIcon-root-MuiSelect-icon {
  color : red;
}

.MuiSvgIcon-root .MuiSvgIcon-fontSizeMedium .MuiSelect-icon .MuiSelect-iconOutlined .MuiSvgIcon-root-MuiSelect-icon{
  color : red;  
}


.account-menu-button {
  
  &:hover {

    .account-menu {
      display: block; 
    }
  }
}


.dropbtn {
  background-color: #04AA6D;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;

  
}

.dropdown {
  position: relative;
  display: inline-block;

  @media only screen and (max-width: 768px) {
    margin-left: 15px;
   }
}

.dropdown-content {
  display: none;
  position: absolute;
  right: 0;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;

  @media only screen and (max-width: 768px) {
    left: 0;
  }

}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  background-color: #cbeeb1;
}

.dropdown-content a:hover {background-color: #7fc348;}
.dropdown:hover .dropdown-content {display: block;}
.dropdown:hover .dropbtn {background-color: #3e8e41;}


.css-bbreus-MuiButtonBase-root-MuiIconButton-root{
  margin-top: -30px;
}