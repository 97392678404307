body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;

  li {
    padding: 10px 0;
  }
 
}

html {
  scroll-behavior: smooth;
}


.table-header {
  background-color: lightgray;
}

.error-message {
  color: #d32f2f;
  font-size: 13px;
  margin-top: -30px;
  position: relative;
  top: -25px;
  left: 15px;
}



td, th {
  white-space: nowrap;
}

td {
  padding: 0 10px !important;
}
